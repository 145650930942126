<template>
  <div>
    <div class="top">
      <div class="l" @click="$router.push('/home')">
        <img
          src="../assets/左.png"
          alt=""
          width="20px"
          height="20px"
          style="margin-left: 20px"
        />
      </div>
      <div class="r">
        <div class="btn" @click="submit">
          <img src="../assets/正确.png" alt="" width="25px" height="25px" />
        </div>
      </div>
    </div>
    <div class="inputbox">
      <h3>输入你想的文案...</h3>
      <textarea
        cols="30"
        rows="10"
        class="txt2"
        placeholder="内容..."
        v-model="txtval"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      txtval: "",
    };
  },
  computed: {
    ...mapState({
      arr: (state) => state.num.arr,
    }),
  },
  methods: {
    ...mapMutations({
      tianjia: "num/tianjia",
    }),
    submit() {
      this.tianjia(this.txtval)
      this.txtval=""
    },
  },
};
</script>
 
<style lang = "less" scoped>
.top {
  height: 2.5rem;
  background-color: whitesmoke;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.l,
.r {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  height: 1.875rem;
  width: 3.75rem;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 15px;
  margin-right: 10px;
}
.inputbox {
  h3 {
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 30px;
  }
  .txt1 {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    outline: none;
    border: 2px solid #ccc;
    resize: none;
    margin-bottom: 20px;
    border-radius: 5px;
    text-indent: 1em;
  }
  .txt2 {
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    outline: none;
    border: 2px solid #ccc;
    resize: none;
    border-radius: 5px;
    text-indent: 1em;
  }
}
</style>